import React, { ReactElement, useEffect } from "react"
import { useKeycloak } from "@react-keycloak/web"
import { PageProps, navigate } from "gatsby"
import { useSelector } from "react-redux"
import { ReducerState } from "@store/index"
import PageSpinner from "@components/shared/page-spinner"
import { useAsnParameter } from "@hooks/use-asn-parameter"
import { AutonomousSystemScopeMapping } from "@models/autonomous-system-scope-mapping"
// import AvailableAutonomousSystemService from "@services/available-autonomous-system-service"
import { AutonomousSystem } from "@models/autonomous-system"
import { LandingPageContent } from "@components/landig-page-content/landing-page-content"

interface ReducerSelected {
  isFetchingScopeMappings: boolean
  scopeMappingList: AutonomousSystemScopeMapping[] | null
  availableAutonomousSystems: AutonomousSystem[] | null
}

interface GraphQLProps {
  site: {
    siteMetadata: {
      externalServices: {
        asAuthorization: {
          baseUrl: string
        }
        asMeasurementsApi: {
          baseUrl: string
        }
      }
      asMeasurements?: {
        baseUrl: string
      }
    }
  }
}

const IndexPage: (
  _: PageProps<GraphQLProps, object> & {
    asn?: string
  }
) => ReactElement = ({ asn: asnString, location }) => {
  /* React Hooks */
  const { keycloak, initialized: isKeycloakInitialized } = useKeycloak()
  const asn = useAsnParameter(asnString)
  const { isFetchingScopeMappings, availableAutonomousSystems } = useSelector<
    ReducerState,
    ReducerSelected
  >((state: any) => {
    return {
      isFetchingScopeMappings: state.asScopeMapping.isFetchingScopeMappings,
      scopeMappingList: state.asScopeMapping.scopeMappingList,
      availableAutonomousSystems:
        state.autonomousSystems.availableAutonomousSystems,
    }
  })
  const isAdmin = keycloak?.hasRealmRole("admin")

  useEffect(() => {
    // O objetivo desse efeito é tratar o direcionamento de um usuário no ingresso da aplicação
    //  - Caso o usuário não esteja logado, ele deve visualizar o conteúdo da landing page
    //  - Caso ele esteja logado e o path seja /home, ele deve visualizar o conteúdo da landing page
    //  - Caso ele esteja logado, o path não seja /home, ele deve visualizar o conteúdo da landing page e o usuário seja administrador,
    //  encaminhar para /measurements/AS14026/dashboard/timeseries/
    //  - Caso ele esteja logado, o path não seja /home, ele deve visualizar o conteúdo da landing page e o usuário não seja administrador,
    //  a lista de ASs disponíveis não seja nula e contenha mais que 0 elementos, encaminhar para /measurements/{PrimeiroASdaLista}/
    //  - Caso ele esteja logado, o path não seja /home, ele deve visualizar o conteúdo da landing page e o usuário não seja administrador,
    // a lista de ASs disponíveis seja nula e contenha menos que 1 elemento, encaminhar para /dashboard/timeseries/
    if (isKeycloakInitialized && keycloak.authenticated) {
      if (
        window.location.pathname.toLowerCase() !== "/home/" &&
        window.location.pathname.toLowerCase() !== "/home" &&
        window.location.pathname.toLowerCase() !== "home"
      ) {
        !isAdmin
          ? availableAutonomousSystems !== null &&
            availableAutonomousSystems.length > 0
            ? navigate(
                "/measurements/AS" +
                  availableAutonomousSystems[0].asn +
                  "/dashboard/timeseries/"
              )
            : navigate("/account/autonomous-systems/")
          : navigate("/measurements/AS14026/dashboard/timeseries/")
      }
    }
    // Disable ESLint, as this effect breaks during development
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableAutonomousSystems, isAdmin, isKeycloakInitialized, keycloak])

  return (
    <>
      {/* {isKeycloakInitialized && keycloak.authenticated && (
        <AvailableAutonomousSystemService />
      )} */}
      <div>
        {isKeycloakInitialized &&
          (!keycloak.authenticated ||
          location.pathname.toLowerCase() === "/home" ||
          location.pathname.toLowerCase() === "/home/" ? (
            <LandingPageContent />
          ) : (
            <div className="min-h-full">
              <div className="bg-white">
                <div className="flex flex-col lg:pl-64">
                  <main className="flex-1">
                    {/* Landing Page*/}
                    <div className="contents">
                      <div className="flex-grow max-w-3xl mx-auto mt-16 pb-32">
                        {(!isKeycloakInitialized ||
                          (keycloak.authenticated &&
                            !isAdmin &&
                            isFetchingScopeMappings)) &&
                        location.pathname.toLowerCase() !== "/home" &&
                        location.pathname.toLowerCase() !== "/home/" ? (
                          <></>
                        ) : (
                          <PageSpinner />
                        )}
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}

export default IndexPage
